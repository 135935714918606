.logo-nav {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}
.ul-nav {
  display: flex;
  gap: 20px;
  cursor: pointer;
  color: white;
}
.fix-nav {
  position: fixed;
  z-index: 20;
  width: 100%;
}
.fix-nav-z0 {
  position: fixed;
  z-index: 0;
  width: 100%;
}
.nav-div2 {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  justify-content: space-around;
  background-color: black;
}
.nav-div3 {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.2px solid #8080804a;
  background-color: black !important;
}

.search-nav {
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* border: 1px solid #80808063; */
  gap: 10px;
  padding: 9px 21px;
  background: white;
}
.navbar-right-h-icons > svg {
  cursor: pointer;
}
.navbar-right-h-icons {
  display: flex;
  gap: 10px;
}
.sticky-nav {
  background-color: black;
}

.ul-black-nav {
  display: flex;
  gap: 20px;
  cursor: pointer;
  color: white;
}
.logo-nav-black {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.search-nav-gray {
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* border: 1px solid #80808063; */
  gap: 10px;
  padding: 9px 21px;
  background: #d3d3d342;
}
.search-input-nav {
  border: none;
  background: white;
}
.search-input-nav1 {
  border: none;
  background: white;
}
.navbar-right-h-icons > svg {
  color: white;
}
.navbar-right-h-icons-black > svg {
  color: black;
  cursor: pointer;
}
.navbar-right-h-icons-black {
  display: flex;
  gap: 10px;
}

.l1 > a {
  color: white;
}
.li-black>a{
  color: white;

}

.l1:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.l1:hover:after {
  width: 100%;
  left: 0;
}
.li-black:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: white;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.li-black:hover:after {
  width: 100%;
  left: 0;
}

.fadeInDown-nav {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-width: 930px) {
  .ul-black-nav,
  .ul-nav {
    display: none;
  }
  .search-nav-gray,
  .search-nav {
    display: none;
  }
  .nav-div2 {
    justify-content: space-between;
  }
  .log-nav-icons {
    display: none;
  }
  .mb-icons-nav {
    display: unset !important;
  }
  .nav-div3 {
    justify-content: space-between;
  }
}

.logo-nav2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.nav-links {
  color: gray;
}
.nav-links-black {
  color: lightgrey;
}
.mb-icons-nav {
  display: none;
}
.mb-icons-nav1 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.head-mobile-menu {
  padding: 25px 20px;
}
.close-icon-nav {
  display: flex;
  justify-content: flex-end;
}
.side-bar-menu-ul {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 0px;
  border-bottom: 0.3px solid #80808052;
}

.side-bar-menu-ul > li {
  color: black;
  font-size: 20px;
  font-weight: 700;
}
.btn-side-nav1 {
  border: 1px solid black;
  padding: 6px 20px;
  color: white;
  background: black;
  border-radius: 17px;
  transition: all 0.5s;
}
.btn-side-nav2 {
  border: 0.3px solid gray;
  padding: 6px 20px;
  color: black;
  background: white;
  border-radius: 17px;
  transition: all 0.5s;
}

.side-nav-btn-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 60px;
}
.side-nav-btn-div > button:active {
  transform: scale(0.8);
  transition: all 0.5s;
}
.none-d-sidenav {
}
.none-f-sidenav {
}

.cart-count-nav {
  display: flex;
  align-items: center;
}
  .nav-div2 {
}
.user-name-nav {
  font-size: 16px;
  color: white;
  font-weight: 100;
  cursor: pointer;
}
.user-name-nav-black {
  font-size: 16px;
  color: white;
  font-weight: 100;
  cursor: pointer;
}
.right-h-user {
  display: flex;
  gap: 10px;
  align-items: center;
}
.right-h-user1 {
  display: flex;

  align-items: center;
  justify-content: space-between;
}
.btn-log-out-nav {
  /* border: 0.3px solid gray; */
  padding: 6px 20px;
  color: black;
  background: white;
  border-radius: 17px;
}
.on-c-op {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  background: white;
}
.search-nav2 {
  display: flex;
  width: 250px;
  border-radius: 5px;
  /* border: 1px solid #80808063; */
  gap: 10px;
  padding: 9px 21px;
  background: #d3d3d342;
}
.search-input-nav2 {
  border: none;
  background: #d3d3d300;
  width: 100%;
}
.search-input-nav2:focus {
  outline: none;
}
.logo-h1-n1 {
  font-size: 19px;
  font-weight: 100;
  cursor: pointer;
}
.op-n-d1{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;

  /* background-color: hsla(0,0%,7%,.36);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px); */
}
.search-nav-gray2 {
  display: flex;
  align-items: center;
  border-radius: 20px;
width: 500px;
  gap: 10px;
  padding: 9px 21px;
  background: #d3d3d342;
}
.on-c-op2 {
  display: flex;
  align-items: center;
  padding: 30px;
  background: white;
  flex-direction: column;
}
.logo-h1-n2 {
  color: #707072;
  font-size: 16px;
}
.category-names-nav{
  font-size: 15px;
  cursor: pointer;
}
.category-names-div{
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 10px;
}


@media only screen and (max-width: 750px) {

.logo-c-op{
  display: none;
}
.search-nav-gray2 {
width: 400px;
}
.op-n-d1 {
background-color: white;
height: 100vh;
}
}
@media only screen and (max-width: 525px) {

  .search-nav-gray2 {
    width: 300px;
    }
    .search-nav11{
      display: none !important;
    }
    .search-nav12 {
      display: none !important;

    }


    .on-c-op {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
  
    }


}
@media only screen and (max-width: 430px) {

  .search-nav-gray2 {
    width: unset;
    }
}
.search-svg{
  cursor: pointer;
  color: black;
  background: white;
  padding: 6px 11px; 
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.search-nav {
cursor: pointer;
}
.search-nav11{
  display: flex;
}
.search-nav12 {
  display: flex;
  border: 1px solid lightgray;
  border-radius: 20px;
  overflow: hidden;
}

.search-input-nav {
  border: none;
  background: white;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.search-input-nav,
.search-input-nav1:focus {
  outline: none;
}
.head-dd{
  transition: all 0.5s;
}
.logo-nav1{
  width: 90px;
}


/* Dropdown Container */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Dropdown Button */
.dropdown-button {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  z-index: 50;
  /* display: none; */
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  border-radius: 4px;
  animation: fadeIn 0.5s ease-in-out;
}

/* Show Dropdown Content on Hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Dropdown Content Links */
.ll2{
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  padding: 10px;
  display: block;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
}
.ll2>span{
padding: 2px;

}

.ll2:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.ll2:hover:after {
  width: 100%;
  left: 0;
}