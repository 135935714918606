.CardNewArrivals-div {
}
.img-card-new-arr {
  width: 300px;
  transition: all 0.5s;
  overflow: hidden;
  height: 378px;
  object-fit: cover;
}
.card-div-main {
  display: flex;
  width: 530px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-radius: 15px;
  gap: 20px;
}
.cards-div-di {
  display: flex;
  justify-content: space-evenly;
  padding: 50px 0px;
  flex-wrap: wrap;
  gap: 10px;
}

.card-heading-p {
  font-size: 17px;
  font-weight: 500;
}
.card-heading-h3 {
  font-size: 20px;
  font-weight: 500;
}
.card-heading-h2 {
  font-size: 12px;
  font-weight: 500;
}
.btn-check-out1 {
  border: 0.5px solid rgba(128, 128, 128, 0.575);
  padding: 10px;
  border-radius: 5px;
}
.right-h-card1 {
  display: flex;
  gap: 15px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  width: 300px;
}
.left-h-card1:hover .img-card-new-arr {
  transform: scale(1.2);
  overflow: hidden;
}
.left-h-card1 {
  transition: all 0.5s;
  height: 378px;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

@media only screen and (max-width: 680px) {
  .card-div-main {
    flex-wrap: wrap;
    justify-content: center;
  }
  .left-h-card1 {
    transition: all 0.5s;

    overflow: hidden;
border-radius: 15px;
height: 270px;
}
.img-card-new-arr {
  width: 230px;
  height: auto; 
}
.right-h-card1 {
width: unset !important;
}
}



.skeleton-loader-card-new {
  width: 530px;
  height: 350px;
  background-color: #f5f5f5;

  border-radius: 15px;
}


.skeleton-loader-card-new2 {
  width: 530px;

  height: 350px;
  background-color: #f5f5f5;

  border-radius: 15px;
}


@media only screen and (max-width: 1200px) {
  .skeleton-loader-card-new2 {
display: none;
  }

}
.ske-car-cat5{
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px;
}