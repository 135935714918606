.cate-div{

}
.wish-div1{
    display: flex;
    justify-content: center;
    padding: 200px 0px;
}
.cate-div2{
    width: 80%;
}
.cate-heding{
    text-align: center;
    font-size: 25px;


}
.card-prod-3 {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 15px;
}
.card-h-img3 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
.card-img-1-p3 {
    width: 270px;

}
.card-prod-3:hover .p-a-card-f3 {
    visibility: visible;
}
.p-a-card-f3  {
    position: absolute;
    display: flex;
    justify-content: center;
    color: white;
    width: 100%;
    padding: 10px;
    background: black;
  }
  .p-a-card-f3 {
    visibility: hidden;
  }
  .q-v-h3 {
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
  }
  .cont-cat-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 90px 0px;
    gap: 50px; */
  }
@media only screen and (max-width: 800px) {

    .card-prod-3 {
        width: 240px;
    }
    .card-img-1-p3 {
        width: 240px;
    }
    .cate-div1 {
        display: flex;
        justify-content: center;
        padding: 20px 0px 100px 0px;
    }
}
@media only screen and (max-width: 670px) {

    .card-prod-3 {
        width: 170px;
    }
    .card-img-1-p3 {
        width: 170px;
    }
    .cont-cat-card {
        /* padding: 30px 0px;
        gap: 20px; */
    }
}
@media only screen and (max-width: 490px) {

    .card-prod-3 {
        width: 130px;
    }
    .card-img-1-p3 {
        width: 130px;
    }
    .cate-div2 {
        width: 95%;
    }
    .cont-cat-card {
       
        gap: 20px;
    }
}
@media only screen and (max-width:300px) {

    .cont-cat-card {
       
        gap: 5px;
    }
    .card-flex-3 {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

.pro-name-h3 {
    font-size: 18px;
}

.card-flex-3 {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;

}
.wish-cards-fun{
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 40px 0px;
}
.pro-name-h-3{
    font-size: 12px;
}
.pro-name-h-1{
    font-size: 11px;
}
.btn-delete-wishitems{
    border: 1px solid;
    padding: 5px 15px;
    border-radius: 5px;
    background: black;
    color: white;
    text-transform: capitalize;
}
.btn-delete-btn{
    display: flex;
    justify-content: flex-end;
}
.btn-delete-btn>button{

border: 1px solid black;
padding: 5px 10px;
border-radius: 5px;
}
.btn-delete-btn>button:active{
transform: scale(0.8);

}

.empty-cart-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.btn-wish-cart{
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    padding: 10px;
}