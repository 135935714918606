.input-form-check[type="text"] {
  box-sizing: border-box;
  width: 100%;
  height: calc(3em + 2px);
  margin: 0 0 1em;
  padding: 6px;
  border: 1px solid #bababa;
  border-radius: 3px;
  background: #fff;
  font-size: 16px;
  resize: none;
  outline: none;
}
.input-form-check[type="text"]:focus {
  border-color: black;
}
.input-form-check[type="text"]:focus + label[placeholder]:before {
  color: black;
}
.input-form-check[type="text"]:focus + label[placeholder]:before,
.input-form-check[type="text"]:valid + label[placeholder]:before {
  transition-duration: 0.2s;
  transform: translate(0, -1.6em) scale(0.9, 0.9);
  font-weight: bold;
}
.input-form-check[type="text"]:valid {
  border-color: black;
}
.input-form-check[type="text"]:valid + label[placeholder]:before {
  color: black;
}
.input-form-check[type="text"]:invalid + label[placeholder][alt]:before {
  content: attr(alt);
}
.input-form-check[type="text"] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.25em;
  margin-top: calc(-3em - 2px);
  margin-bottom: calc((3em - 1em) + 2px);
}
.input-form-check[type="text"] + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(0.5em + 2px);
  padding: 0 2px;
  color: #7d7d7d;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.check-d-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px 130px 0px;
}

.check-d-2 {
  width: 90%;
}
.check-h1 {
  font-size: 17px;
}

.log-btn-check {
  border: 1px solid;
  padding: 5px 14px;
  border-radius: 15px;
}
.log-btn-div-check {
  padding: 20px 0px;
}
.log-btn-check:hover {
  background-color: rgba(128, 128, 128, 0.164);
}
.form-check-address {
  padding: 25px 0px;
}
.form-check-div2 {
}
.left-h-check {
  /* width: 30%; */
}
.d-f-c-8 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.check-content-head-div {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.check-proimg {
  width: 100px;
}
.check-out-btnn-cart1 {
  border: 1px solid;
  padding: 10px ;
  border-radius: 5px;
  background: black;
  color: white;
  width: 100%;
}
@media only screen and (max-width:900px) {
  .check-content-head-div {
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
}


}
@media only screen and (max-width:500px) {


.check-d-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px 50px 0px;
}
.d-f-cart12 {

  gap: 6px !important;
}
}

.d-f-car-6{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

}

@media only screen and (max-width:600px) {
  .d-f-cart12 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;
    flex-direction: column;
}


}

@media only screen and (max-width:360px) {
  .d-f-car-6 {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  

}

.d-f-cart12 {
  display: flex;

  gap: 6px;

}

.address-adcheckout{
  padding: 10px 0px;
}

.address-adcheckout-h1{
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
}

.address-adcheckout-h1:hover{
color: gray;
}

.chekout-d1 {

  padding: 50px 0px;
  background: #fafafa;

}
.check-heading{


  text-align: center;
  font-size: 30px;
  padding: 10px 0px;
}
.checkout-d2{
  width: 100%;
  display: flex;
  justify-content: center;
}
.checkout-d3 {
  width: 80%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
}
.lh-checkout{
  width: 700px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rh-checkout{
  
}
.bill-dd-h1{
  font-size: 15px;
  font-weight: 600;
}
.lh-check-2 {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 20px;
  border-radius: 5px;
  flex-direction: column;
}
.lh-c1 {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 20px;
  border-radius: 5px;
  flex-direction: column;
}



.lh-check-3>h1 {
  font-size: 15px;
  font-weight: 600;
}
.lh-check-3{
  display: flex;
  justify-content: space-between;
}
.lh-check-4 {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}
.lh-check-4>span{
  font-size: 13px;
}
.lh-c2{

}
.lh-c3>span{
  font-size: 12px;
}
.lh-c4 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.lh-c5{
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.lh-c5>span{
  line-height: 1.2307692308;
  font-size: .8125rem;
  font-weight: 400;
}
.lh-form-in {
  gap: 5px;
  display: flex;
  flex-direction: column;
}
.form-group-lh-checkout{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input:focus{
  outline: none;
}
select:focus{
  outline: none;
}
.lh-form-in >input{
  padding: 11px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid gray;
}
.lh-form-in >label{
  font-size: 14px;

}
.lh-c7 {
  display: flex;
  justify-content: space-between;
}
.ih-c8{
  width: 48%;
}

.lh-form-in >select {
  padding: 11px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid gray;
}

.lh-c8{
  display: flex;
  justify-content: center;
}
.lh-c8 >button{
  background: black;
  padding: 10px 10px;
  color: white;
  width: 50%;
  border-radius: 5px;
}
.rh-c-p1{
  font-size: 12px
}
.rh-c-p1>span{
text-decoration: underline;
cursor: pointer;
}
.rh-checkout-t6>button {
  background-color: black;
  padding: 15px;
  color: white;
  border-radius: 5px;
  width: 100%;
}

@media(max-width:1000px){

  /* .checkout-d3 {
      width: 95%;
      display: flex;
      gap: 10px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
  }
  .lh-checkout {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
  } */
  .checkout-d3 {
      width: 95%;

  }
}
@media(max-width:870px){



  .checkout-d3 {

      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

  .lh-checkout {
      width: 100%;
  }
  .check-d4 {
      width: auto !important;

  }
  .rh-checkout {
      width: 100%;
  }
}

.check-d4 {
  width: 350px;
  padding: 20px;
  background: white;
  border-radius: 5px;
}

.rh-checkout-t67>button{
  background-color:gray;
  padding: 15px;
  color: white;
  border-radius: 5px;
  width: 100%;
  cursor :not-allowed;
}