.home-c1{

    width: 100%;
    display: flex;
    justify-content: center;
}
.home-c2{
    width: 80%;
}
.home-c3 {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 50px 0px;
    flex-wrap: wrap;
}
.home-c4{
width: 500px;
}
.home-c4>img {
    width: 100%;
cursor: pointer;
    border-radius: 5px;
max-height: 600px;
    object-fit: cover;
}

@media (max-width:500px) {
    .home-c2{
        width: 95%;
    }
    .home-cd2 {

  
        flex-direction: column;
        gap: 20px;
        padding: 20px 0px !important;
    
    }
}
.home-cd1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.home-cd2 {
    display: flex;
    justify-content: space-around;
    padding: 50px 0px;
    flex-wrap: wrap;
}

.icon-cd2{
    background: black;
    color: white;
    padding: 10px;
    border-radius: 50%;
    font-size: 30px;
}

.home-cd1>span {
  
    font-size: 15px;
    font-weight: 600;
}



.bg-h-c1{
    background-image: url('../../assets/images/bg4.png');
    /* height: 500px; */
    /* background-attachment: fixed; */
    padding: 50px;
}

.h5-card-d1{
    display: flex;
    justify-content: space-evenly;
    /* gap: 20px; */
    flex-wrap: wrap;
    gap: 10px;
}
.h5-card-d2{
    border: 1px solid white;
    width: 250px;
    display: flex;
    padding: 10px;
    /* background: white; */
    cursor: pointer;
}
.img-card-h5 {
    width: 100%;
    height: 100%;
    
    object-fit: cover;
}

.new-div1-1 {
    display: flex;
    justify-content: center;
    padding: 50px 0px 0px 0px;
}

