.new-div1 {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
.new-div2 {
  width: 90%;
}
.new-h1 {
  font-size: 25px;
}
.border-line {
  border: 1px solid;
}
.new-flex-1 {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.carousel-div1 {
  display: flex;
  justify-content: space-around;
}
.new-div5 {
  display: flex;
  margin-left: 10px;
  justify-content: center;
}
.slider-img-1 {
  width: 250px;
  border-radius: 5px;
  height: 330px;
  object-fit: cover;
}
.new-c-top {
  /* margin-top: 50px; */
  padding: 50px 0px;
}
.slide-heading {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.new-div6 {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.new-h2 {
  font-size: 12px;
  font-weight: 700;
}
.slider-img-carousel {
  width: 100%;
  object-fit: cover;
  height: 70vh;
}

@media only screen and (max-width: 670px) {
  .slider-img-1 {
    width: 180px;

    height: 250px;
  }
}


@media only screen and (max-width: 500px) {
  .slider-img-1 {
    height: 103px !important;
    width: 97px !important;

  }
  .slide-heading {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
}
.new-div2 {
  width: 98%;
}
}



@media only screen and (max-width: 320px) {

  .skeleton-loader-card-cat {
    width: 200px !important;
    height: 200px !important;
    background-color: #f5f5f5;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
  }
  
}

.custom-next > svg,
.custom-prev > svg {
  color: gray;
  font-size: 30px;
  /* border: 1px solid; */
  border-radius: 50%;
  background: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.skeleton-loader-card-cat {
  width: 260px;
  height: 350px;
  background-color: #f5f5f5;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 1400px) {

.carousel-div1 {
  display: flex;
  justify-content: unset;
}
}


@media only screen and (max-width: 1200px) {
  .skeleton-loader-card-cat2 {
    display: none;
  }
}

.card-head-ske {
  font-size: 12px;
  color: lightgray;
  text-align: center;
}
.ab-div-ske {
  /* position: absolute;
  bottom: 0; */
}
.ab-div-ske {
  background-color: #f5f5f5;
  border-radius: 15px;
  width: 177px;
  padding: 6px 0px;
  /* padding: 9px 0px; */
}


/* @media only screen and (max-width: 1400px) {


.new-div5 {

  margin-left: unset ;

}
} */
