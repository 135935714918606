.contactus-h1{
    font-size: 18px;
    text-align: center;
    padding: 10px 0px;
    text-transform: uppercase;
}
.contact-no-h1{
    font-size: 18px;
}
.d-f-contact {
    display: flex;
    justify-content: space-evenly;
    padding: 40px 0px;
}
.d-f-contact2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.contact-us-div3 {
    display: flex;
    justify-content: center;
}
.contact-us-div4{
width: 50%;
}
.df-contact-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}
.w-1-c{
width: 100%;
}
.form-check-address2{

}







@media only screen and (max-width: 700px) {


.d-f-contact {
    display: flex;
    justify-content: space-evenly;
    padding: 40px 0px;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
}
}



@media only screen and (max-width: 1000px) {
    .contact-us-div4{
        width: 80%;
        }

    }

    @media only screen and (max-width: 500px) {
        .contact-us-div4{
            width: 100%;
            }
    
        }


        .form-check-address2{
            padding:15px 0px;
        }
        





        .input-form-check2[type="text"] {
            box-sizing: border-box;
            width: 100%;
            height: calc(3em + 40px);
            margin: 0 0 1em;
            padding: 6px;
            border: 1px solid #bababa;
            border-radius: 3px;
            background: #fff;
            font-size: 16px;
            resize: none;
            outline: none;
          }
          .input-form-check2[type="text"]:focus {
            border-color: black;
          }
          .input-form-check2[type="text"]:focus + label[placeholder]:before {
            color: black;
          }
          .input-form-check2[type="text"]:focus + label[placeholder]:before,
          .input-form-check2[type="text"]:valid + label[placeholder]:before {
            transition-duration: 0.2s;
            transform: translate(0, -1.6em) scale(0.9, 0.9);
            font-weight: bold;
          }
          .input-form-check2[type="text"]:valid {
            border-color: black;
          }
          .input-form-check2[type="text"]:valid + label[placeholder]:before {
            color: black;
          }
          .input-form-check2[type="text"]:invalid + label[placeholder][alt]:before {
            content: attr(alt);
          }
          .input-form-check2[type="text"] + label[placeholder] {
            display: block;
            pointer-events: none;
            line-height: 1.25em;
            margin-top: calc(-3em + -40px);
            margin-bottom: calc((6em - 1em) + 2px);
          }
          .input-form-check2[type="text"] + label[placeholder]:before {
            content: attr(placeholder);
            display: inline-block;
            margin: 0 calc(0.5em + 2px);
            padding: 0 2px;
            color: #7d7d7d;
            white-space: nowrap;
            transition: 0.3s ease-in-out;
            background-image: linear-gradient(to bottom, #ffffff, #ffffff);
            background-size: 100% 5px;
            background-repeat: no-repeat;
            background-position: center;
          }