.head-Product {
  padding: 100px 0px;
}
.carousel-img-product {
}

.carousel-root {
  outline: none;
  display: flex;
  flex-direction: row-reverse;
}
.carousel .thumbs {
  display: flex;
 
  flex-direction: column;
}
.carousel {
  position: relative;
  width: unset !important;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  /* border-radius: 15px; */
  overflow: hidden;
}
.pro-name-h5{
  font-size: 9px;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;

  padding: 0px !important;
}

.carousel .control-dots {
  /* bottom: 0; */
  display: none !important;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  display: none !important;
}
.carousel .carousel-status {
  display: none !important;
}

.main-carousel {
  width: 45%;
}

.carousel-product {
  display: flex;
  /* width: 90%; */
  justify-content: center;
  gap: 29px;
  flex-wrap: wrap;
}
.carousel .thumbs {
  transition: all 0.15s ease-in;

  position: relative;
  list-style: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  /* border-radius: 15px; */
  overflow: hidden;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 0px solid #fff;
  padding: 0px;
}

.carousel .control-dots {
  /* bottom: 0; */
  display: none !important;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  display: none !important;
}
.carousel .carousel-status {
  display: none !important;
}
.carousel .thumbs-wrapper {
  /* margin: 20px 0px !important; */
  overflow: hidden;
}
.main-carousel {
  width: 45%;
}

.carousel-product {
  display: flex;
  /* width: 90%; */
  justify-content: center;
  gap: 29px;
  flex-wrap: wrap;
}
.carousel .thumbs {
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .thumb {
  /* border-radius: 15px; */
}
@media only screen and (max-width: 730px) {
  .carousel .thumbs-wrapper {
    margin: 20px 0px !important;
    overflow: hidden;
  }
  .carousel-product {
    display: flex;
    justify-content: center;
    /* gap: 10px; */
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .main-carousel {
    width: 80%;
  }
  .product-data1 {
    width: 80%;
    color: white;
    font-family: "Lato", Arial, sans-serif;
  }
  .carousel-root {
    outline: none;
    display: flex;
    flex-direction: column;
  }

 

  .carousel .thumbs {
    flex-direction: row;
  }
  .carousel {
    /* width:100% !important; */
  }
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
}
.carousel .thumbs {
  transform: unset !important;
}
.carousel .slide img {

  vertical-align: top;
  border: 0;
  height: 600px ;
  object-fit: cover;
}

@media only screen and (min-width: 730px) {
  .carousel .slide img {
    width: 500px !important;
;
  }


  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 500px !important;
    transition: height 0.15s ease-in;
  }
}

@media only screen and (max-width: 1000px) {
  .main-div-carousel {
    display: flex;
    justify-content: center;
    gap: 60px;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 450px) {
  .carousel .slide img {
    /* width: 160px !important; */
    border-radius: 5px !important;
    height: 400px !important;
  }

  .carousel .thumb {
    width: 50px !important;
  }
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 2px solid #333 !important;
}

.right-h-details-product {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 25px;
}
.heading-product-r1 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 100;
}
.price-product-r1 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 100;
}
.carousel .slider-wrapper {
  border-radius: 5px;
}
.main-div-carousel {
  display: flex;
  justify-content: center;
  gap: 60px;
}
.in-tax {
  font-size: 13px;
  font-weight: 500;
}
.av-color {
  font-size: 13px;
  font-weight: 500;
}
.av-size {
  font-size: 13px;
  font-weight: 500;
}

.color-select {
  cursor: pointer;

  border: 1px solid gray;

  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 1px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.color-select-1 {
  cursor: pointer;
  border: 1px solid gray;
  background: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 1px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.select-c-div {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.s-c-div1 {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.product-div1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-div2 {
  width: 90%;
  padding: 50px 0px;
}
.ss-pro1 {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

.s-c-size-div {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding-top: 8px;
  text-align: center;
  min-width: 29px;
  height: 23px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.5s;
}
.s-c-size-div:hover {
  background-color: black;
  color: white;
}
.s-c-size-div2 {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding-top: 8px;
  text-align: center;
  min-width: 29px;
  height: 23px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.5s;
  color: white;
  background-color: black;
}

.text-check-s {
  font-size: 10px;
  cursor: pointer;
  color: #176d93;
}
.chart-div > svg {
  cursor: pointer;
}
.btn-add-to-bag {
  display: flex;
  border: 1px solid;
  padding: 10px 63px;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  background: black;
  color: white;
  border-radius: 5px;
}
.btn-add-to-bag:active {
  transform: scale(0.8);
}
.thumb {
  width: 60px !important;
  border-radius: 5px;
}

.btn-s-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.btn-s-con > p {
  font-size: 11px;
}
.pro-details-2 {
  font-size: 13px;
  font-weight: 400;
}
.pro-details-ul > li {
  list-style-type: disc;
  font-size: 13px;
}
.s-c-div2 {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}
.btn-add-to-wish {
  display: flex;
  border: 1px solid;
  padding: 10px 40px;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  background: white;
  color: black;
  border-radius: 5px;
}
.btn-add-to-wish:active {
  transform: scale(0.8);
}

.product-description {
  padding: 50px 0px;
}
.pro-description-h1 {
  font-size: 15px;
  font-weight: 700;
  color: #585858;
}
.line-texts {
  content: "";
  background: lightgray;
  height: 1px;
  width: 443px;
}
.head-c-decs {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 1600px) {
  .line-texts {
    height: 1px;
    width: 250px;
  }
}
@media only screen and (max-width: 1040px) {
  .line-texts {
    height: 1px;
    width: 150px !important;
  }
}
@media only screen and (max-width: 730px) {
  .line-texts {
    height: 1px;
    width: 100px !important;
  }
  .head-c-decs {
    gap: 4px;
  }
  .details-centent-a2 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 430px) {
  .line-texts {
    display: none;
  }
}

.details-para1 {
  font-size: 13px;
  font-weight: 400;
  color: #585858;
}

.details-centent-a1 {
  display: flex;
  justify-content: center;
}
.details-centent-a2 {
  width: 75%;
}
/* .swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-related-proimg>img{
  width: 299px;
  height: 450px;
  object-fit: cover;
} */
.card-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 20px;
}

.card-container {
  display: flex;
}

.card {
  flex: 0 0 300px;
  margin-right: 20px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.card.active {
  transform: scale(1.1);
}

.nav-btn {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.nav-btn:hover {
  background-color: #d1d1d1;
}

.prev-btn {
  margin-right: 10px;
}

/* Add media queries for responsive design */
@media (max-width: 768px) {
  .card {
    flex-basis: auto;
    margin-right: 10px;
  }
}

.slider-img-2 {
  width: 230px;
  border-radius: 5px;
  height: 280px;
  object-fit: cover;
}
@media only screen and (max-width: 1000px) {
  .slider-img-2 {
    width: 220px;
    /* border-radius: 15px; */
  }
}
@media only screen and (max-width: 800px) {
  .slider-img-2 {
    width: 180px;
    /* border-radius: 15px; */
  }
}

@media only screen and (max-width: 670px) {
  .slider-img-2 {
    width: 180px;
    /* border-radius: 15px; */
  }
  .pro-sket-imc {
    background: #f5f5f5;
    width: 300px !important;
    height: 300px !important;
    border-radius: 5px;
  }

}

@media only screen and (max-width: 500px) {
  .slider-img-2 {
    width: 200px;
    /* border-radius: 15px; */
  }
  

}
@media only screen and (max-width: 320px) {
  .slider-img-2 {
    width: 200px;
    /* border-radius: 15px; */
  }
}
.p-a-card-f1{
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 0;

  background: black;
  padding: 10px 0px;
  justify-content: center;
  color: white;

}
.card-relative-pro {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  /* height: 228px; */
}

.p-a-card-f1{
  visibility: hidden;
}
.new-div6:hover .p-a-card-f1{
  visibility: visible;

}
.new-div6{
  cursor: pointer;
}
.q-v-h2{
font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;
}

.av-not-av{
  padding: 10px 0px;
  color: green;
}
.av-not{
  padding: 10px 0px;
  color: red;
}
.side-cart-show{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  color: black;
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
}

.close-icon-nav>svg{
cursor: pointer;
}


.pro-sket-imc {
  background: #f5f5f5;
  width: 500px;
  height: 500px;
  border-radius: 5px;
}
.r-h-ske-p{
  background: #f5f5f5;
  width: 250px;
  height: 300px;
  border-radius: 5px;
}

.skeleton-loader-card-cat1{
  width: 200px;
  height: 250px;
  background-color: #f5f5f5;

  border-radius: 5px;
}

.carousel-div12{
  display: flex;
}

.image-zoom-main>img{

  width: 500px !important;
  height: 600px;
  object-fit: cover;
  /* border-radius: 5px; */

}
.image-zoom-main{

  border-radius: 5px;
}

.thumb-img-product{
  width: 70px;
  height: 70px;
  cursor: pointer;
  border-radius: 5px;
}

.thumb-div-p1 {
  display: flex;
  gap: 5px;
  margin: 10px 0px;
}

.lf-img-car1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 550px) {

.image-zoom-main>img {
  width:100% !important;
  height:  auto;
}
}